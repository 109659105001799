import './Footer.css'
import logopa from "./../../Images/lvpasansfonsoff.png";


export default function Footer() {
  return (
    <div className='main-footer'>
      <div className="mini-footer">
        <img className="logo-pam" src={logopa} alt="" />
      &copy; 2023{" "}
      </div>
      <div className='footer-mentions'><a className='footer-mentions-link' href="/mentions-legales" >Mentions Légales / Politique de confidentialité</a></div>
    </div>
  );
}
