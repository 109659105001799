import './NotFound.css'
export default function NotFound() {
  return (
    <div className="containerNotFound">
      <div className="containerTxtNotFound">
          <div className="logoSiteNotFound">

          </div>
        <p className="notFoundText">
          La page que vous demandez est introuvable{" "}
        </p>
        <a className="returnHome" href="https://lvpaweb.fr/">Retourner sur le site</a>
      </div>
    </div>
  )
}
