import "./MesServices.css";

import t2me from "../../Images/lvpaweb.webp";
import test from "../../Images/creation_site.webp";
import laptop from "../../Images/laptopPa.jpg";
import { useEffect } from "react";

export default function MesServices() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="main-services" id="equipe1">
      {/* <Navbar/> */}

      {/* <div className="test"> */}
      <div className="content-services">
        <h2 className="titre-services">LVPA WEB</h2>
        <h1 className="descriptif-site">Création de sites Web</h1>
        <div className="texte-services">
          <p className="texte-para">
            Si vous souhaitez un site vitrine ou un site personnalisé, vous êtes
            tombé au bon endroit, chez <span className="lvpa">"LVPA WEB"</span> nous sommes là pour vous aider à
            construire le site qui vous ressemble et qui correspond à vos
            envies. Nous sommes là pour vous aider à mettre en place votre
            projet et vous accompagner jusqu'à la mise en ligne de votre site.
          </p>
          <p className="texte-para">
            Il est crucial pour les propriétaires de sites web de s'assurer que
            leur contenu est bien positionné pour atteindre leur public cible.
            En effet, la conception et la mise en page de votre site web peuvent
            avoir un impact significatif sur votre audience et vos résultats.
            L'une des considérations les plus importantes pour tout site web est
            la partie "responsive", c'est-à-dire la capacité du site à s'adapter
            à différents types d'écrans, notamment les smartphones et les
            tablettes. Les sites web qui ne sont pas optimisés pour les
            appareils mobiles risquent de perdre une partie importante de leur
            public. Il est donc essentiel de veiller à ce que votre site web
            soit conçu de manière à offrir une expérience utilisateur optimale,
            quelle que soit la taille de l'écran utilisé pour y accéder. En
            veillant à ces aspects, les propriétaires de sites web peuvent
            s'assurer que leurs visiteurs sont au bon endroit pour trouver
            {/* l'information dont ils ont besoin et profiter d'une expérience */}
            utilisateur agréable et pratique.
          </p>
        </div>
      </div>

      <div className="content-services-images">
        <div className="image-services">
          <div className="image-services-content">
            <img className="image-carte-services" loading="lazy" src={t2me} alt="" />
          </div>
          <div className="content-carte-bas">
            <h3 className="carte-titre">Site vitrine <sup className="para-sup">*(1)</sup></h3>
            <p className="carte-para">
              {/* Vous souhaitez un site vitrine pour présenter votre activité ou
              votre projet ? Ensemble nous allons mettre en forme vos idées.Le
              site Vitrine c'est le site qui vous représente. */}
            </p>
            <p className="perso-site mb-0">
              Site comprenant au Maximum 5 pages
            </p>
            {/* <img className="laptop" src={laptop} alt="" /> */}
          </div>
        </div>
        <div className="image-services">
          <div className="image-services-content">
            <img className="image-carte-services" loading="lazy" src={test} alt="" />
          </div>
          <div className="content-carte-bas">
            <h3 className="carte-titre">Site Personnalisé <sup className="para-sup">*(2)</sup></h3>
            <p className="carte-para">
              {/* Création de votre site personnalisé, exemple de site Blog, forum,
              site de E-commerce etc... */}
            </p>
            <div className="content-perso-site">
              <p className="perso-site ">Site en fonction de vos besoins</p>
            </div>
            {/* <img className="laptop" src={laptop} alt="" /> */}
          </div>
        </div>
      </div>

      <div className="choix-site">
        <div className="texte-services">
          <p className="carte-para"><sup className="para-sup">*(1)</sup> Un site vitrine est un type de site web qui sert à présenter une entreprise ou une organisation en ligne. Il est généralement constitué de plusieurs pages contenant des informations sur l'entreprise, ses produits ou services, son histoire, son équipe, ses coordonnées, etc. En somme, un site vitrine est un moyen efficace de se présenter en ligne et de renforcer sa présence sur le web.</p>
          <p className="carte-para"><sup className="para-sup">*(2)</sup> Un site personnalisé est un type de site web qui répond spécifiquement aux besoins d'un individu ou d'une entreprise. Il peut comporter plus de 5 pages, chacune dédiée à un objectif particulier, tel que la présentation de produits ou services, la mise en place d'un blog, la publication d'actualités, la présentation de projets, etc.
          
          Ce type de site web peut également inclure un système de connexion avec un back-end et une base de données, permettant aux visiteurs de s'inscrire, de se connecter et d'accéder à des fonctionnalités spéciales, telles que des tableaux de bord personnalisés ou des pages de profil utilisateur.
          
          Le back-end est la partie du site web qui n'est pas visible par les visiteurs, mais qui gère la logique et les données. Il permet aux propriétaires de sites web de gérer les fonctionnalités du site, telles que la gestion des utilisateurs, la gestion des commandes, la gestion de contenu, etc.
          
          La base de données est l'endroit où toutes les informations du site web sont stockées. Elle permet de stocker les informations sur les utilisateurs, les produits, les commandes, etc. et de les récupérer en temps réel pour afficher les données à l'utilisateur.
          
          En somme, un site personnalisé avec un système de connexion avec back-end et base de données offre des fonctionnalités avancées pour répondre aux besoins spécifiques d'un individu ou d'une entreprise, offrant ainsi une expérience utilisateur personnalisée et une gestion efficace du contenu et des données.</p>
        </div>

        
      </div>
      {/* </div> */}
    </div>
  );
}
