import { Link } from "react-router-dom";
import "./mentions.css";

export default function MentionsLegales() {
  return (
    <div className="main-mentions">
      <div className="main-sepa-1">
        <div className="sepa1"></div>
      </div>

      <h1 className="mentions-titre1 titre-histoire">
        <span className="histoire-top-border">Mentions </span>
        <span className="histoire-couleur">Légales</span>
      </h1>

      <h2 className="mentions-sous-titre">Editeur du site</h2>
      <p className="mentions-para">
        Le présent site web est édité par EI LVPA WEB / Laignel Vergain
        Pierre-Alain.
      </p>

      <p className="mentions-para">SIREN 982197899</p>
      <p className="mentions-para">Adresse : 2 Rue Gustave Charpentier</p>
      <p className="mentions-para">14100 LISIEUX</p>
      <p className="mentions-para">Tel : 07 75 74 04 65</p>
      {/* <p className="mentions-para">Téléphone : </p> */}
      <p className="mentions-para">
        Directeur de la publication : Laignel Vergain Pierre-Alain
      </p>
      <h2 className="mentions-titre">Hébergeur</h2>
      <p className="mentions-para">Le présent site web est hébergé par :</p>
      <p className="mentions-para">
        L'hébergeur du Site est la société O2Switch, dont le siège social est
        situé au :
      </p>
      <p className="mentions-para">Chem. des Pardiaux, </p>
      <p className="mentions-para">63000 Clermont-Ferrand</p>
      <p className="mentions-para">04 44 44 60 40 </p>

      <h2 className="mentions-titre">Informations du site internet LVPA WEB</h2>
      <p className="mentions-para">
        Les informations et documents présents sur le site{" "}
        <a className="mentions-link" href="https://lvpaweb.fr" target="_blank">
          https://lvpaweb.fr
        </a>{" "}
        sont données à titre indicatifs et n’engagent en rien la responsabilité
        du propriétaire du site. Le propriétaire ne peut garantir l’exhaustivité
        et la véracité des informations présentes sur le site internet ainsi que
        l’absence de modification par un tiers (piratage, virus ...). Seul
        l’internaute est responsable de l’utilisation qu’il fait du contenu du
        site. Les éléments présentés par le site sont susceptibles de
        modifications sans préavis et sont mis à disposition des internautes,
        sans garantie aucune. Cependant le propriétaire du site internet
        s’efforcera de prévenir les utilisateurs en cas de mise à jour.
      </p>
      <div className="main-sepa-1">
        <div className="sepa2"></div>
      </div>

      <h2 className="mentions-titre1 titre-histoire">
        <span className="histoire-top-border">Politique de </span>
        <span className="histoire-couleur">confidentialité</span>
      </h2>

      <p className="mentions-para">
        Cette politique de confidentialité a pour objectif de vous expliquer
        pourquoi nous recueillons vos données et comment nous nous engageons à
        les protéger.
      </p>
      <p className="mentions-para">
        LVPA WEB s'engage en faveur de la protection de vos données personnelles
        et de votre vie privée.
      </p>
      <p className="mentions-para">
        À ce titre, et en application du Règlement Général de Protection des
        Données (ci-après dénommé "RGPD"), nous vous communiquons ci-après les
        conditions dans lesquelles vos données personnelles sont appelées à être
        traitées par nos soins.
      </p>
      <h2 className="mentions-titre">
        Quelles données personnelles traitons-nous ?
      </h2>
      <h3 className="mentions-droits">
        <p className="mentions-sous-titre">Finalités :</p>
      </h3>
      <p className="mentions-para">
        Nous sommes susceptibles de recueillir et de conserver vos données à
        caractère personnel, notamment pour :
      </p>
      <p className="mentions-para">
        Traiter et répondre à vos messages, Traiter et répondre à vos demandes
        d’exercice de droits.
      </p>
      <p className="mentions-para">
        <strong>Catégories des données :</strong>
      </p>
      <p className="mentions-para">
        Des coordonnées (par exemple nom, prénom, numéro de téléphone, email) ;
      </p>
      <h2 className="mentions-titre">Fondement juridiques des traitements</h2>
      <p className="mentions-para">
        Les traitements de données à caractère personnel mis en œuvre sont
        fondés :
      </p>
      <p className="mentions-para">
        • Soit sur le consentement de la personne concernée (Article 6.1.a du
        RGPD) pour tous les traitements qui nécessitent le recueil préalable du
        consentement. Dans les formulaires en ligne, les champs obligatoires
        sont marqués d’un astérisque. A défaut de réponse aux questions
        obligatoires, nous ne serons pas en mesure de vous fournir les services
        demandés.
      </p>
      <p className="mentions-para">
        • Soit pour l’exécution d’un contrat ou l’exécution de mesures
        précontractuelles,
      </p>
      <p className="mentions-para">
        • Soit pour la poursuite d’un intérêt légitime (Article 6.1.e du RGPD).
      </p>
      <p className="mentions-para">
        • Soit pour le respect d’une obligation légale ou règlementaire ;
      </p>
      <p className="mentions-para">
        Vos données sont conservées pour la durée nécessaire à l’accomplissement
        des finalités mentionnées ci-dessus.
      </p>
      <p className="mentions-para">
        La durée de conservation des données personnelles des Clients dépend de
        la finalité concernée.
      </p>
      <p className="mentions-para">
        Dans ce cadre, les données personnelles des Clients sont conservées le
        temps nécessaire à l’accomplissement de leur requête. A défaut d’une
        quelconque réalisation, les données personnelles sont supprimées dans
        les délais recommandés par la Commission Nationale Informatique et
        Libertés (CNIL), au terme d’un délai de trois ans à compter de leur
        collecte, sous réserve :
        <p className="mentions-para">
          - des possibilités et obligations légales en matière d’archivage,
        </p>
        <p className="mentions-para">
          - des obligations de conservation de certaines données, à des fins
          probatoires, et/ou d’anonymisation de celles-ci.
        </p>
        <p className="mentions-para">
          {" "}
          - Les données personnelles du Client collectées et traitées, pour les
          besoins d’exécution des offres, sont conservées pour la durée
          nécessaire à la gestion de la relation contractuelle.
        </p>
      </p>

      <p className="mentions-para mt20">
        Par dérogation, les données personnelles requises pour à l’établissement
        de la preuve d’un droit ou d’un contrat sont archivées conformément aux
        dispositions légales (5 ou 10 ans après la fin de la relation
        commerciale selon le cas).
      </p>
      <h2 className="mentions-titre">
        Quels sont les destinataires de vos données ?
      </h2>
      <p className="mentions-para">
        Vos données personnelles sont destinées à LVPA WEB.
      </p>
      <p className="mentions-para">
        Nos services internes : Elles sont traitées par le personnel de nos
        différents services tel que le service commercial ou le service en
        charge de la sécurité informatique.
      </p>

      <p className="mentions-para">
        Les données personnelles des Clients collectées sont hébergées en
        France.
      </p>

      <h2 className="mentions-titre">
        Comment LVPA WEB préserve la sécurité de vos données ?
      </h2>
      <p className="mentions-para">
        Nous mettons en place toutes les mesures organisationnelles et
        techniques permettant d’assurer un niveau approprié de sécurité à vos
        données personnelles, et notamment d'éviter toute perte de
        confidentialité, d'intégrité ou d’accessibilité.
      </p>
      <p className="mentions-para">
        Nous effectuons fréquemment la sauvegarde des données, Met en place des
        mesures techniques et organisationnelles pour assurer que la
        conservation des données personnelles des Clients est sécurisée et ce,
        pendant la durée nécessaire à l’exercice des finalités poursuivies.
      </p>
      <h2 className="mentions-titre">
        Quels sont vos droits sur vos données personnelles ?
      </h2>
      <p className="mentions-para">
        Conformément à la Réglementation Applicable, vous disposez des droits
        suivants :
      </p>
      <p className="mentions-para mt20">
        <h3 className="mentions-droits">Un droit de rectification :</h3> vous
        avez le droit d’obtenir la rectification des données inexactes vous
        concernant.
      </p>
      <p className="mentions-para">
        Vous avez également le droit de compléter les données incomplètes vous
        concernant, en fournissant une déclaration complémentaire. En cas
        d’exercice de ce droit, nous nous engageons à communiquer toute
        rectification à l’ensemble des destinataires de vos données,
      </p>
      <p className="mentions-para mt20">
        <h3 className="mentions-droits">Un droit d’effacement : </h3> dans
        certains cas, vous avez le droit d’obtenir l’effacement de vos données.
        Cependant, ceci n’est pas un droit absolu et nous pouvons pour des
        raisons légales ou légitimes conserver ces données.
      </p>
      <p className="mentions-para mt20">
        <h3 className="mentions-droits">
          Un droit à la limitation du traitement :{" "}
        </h3>{" "}
        dans certains cas, vous avez le droit d’obtenir la limitation du
        traitement sur vos données,
      </p>
      <p className="mentions-para mt20">
        <h3 className="mentions-droits">
          Un droit à la portabilité des données :{" "}
        </h3>{" "}
        vous avez le droit de recevoir vos données que vous nous avez fournies,
        dans un format structuré, couramment utilisé et lisible par une machine,
        pour votre usage personnel ou pour les transmettre à un tiers de votre
        choix. Ce droit ne s’applique que lorsque le traitement de vos données
        est basé sur votre consentement, sur un contrat ou que ce traitement est
        effectué par des moyens automatisés,
      </p>
      <p className="mentions-para mt20">
        <h3 className="mentions-droits">
          Un droit d’opposition au traitement :{" "}
        </h3>{" "}
        vous avez le droit de vous opposer à tout moment au traitement de vos
        données pour les traitements basés sur notre intérêt légitime, une
        mission d’intérêt public et ceux à des fins de prospection commerciale.
        Ceci n’est pas un droit absolu et nous pouvons pour des raisons légales
        ou légitimes refuser votre demande d’opposition,
      </p>
      <p className="mentions-para mt20">
        <h3 className="mentions-droits">
          Le droit de retirer votre consentement à tout moment :{" "}
        </h3>{" "}
        vous pouvez retirer votre consentement au traitement de vos données
        lorsque le traitement est basé sur votre consentement. Le retrait du
        consentement ne compromet pas la licéité du traitement fondé sur le
        consentement effectué avant ce retrait,
      </p>
      <p className="mentions-para mt20">
        <h3 className="mentions-droits">
          Le droit de déposer une plainte auprès d’une autorité de contrôle :{" "}
        </h3>{" "}
        vous avez le droit de contacter votre autorité de protection des données
        pour vous plaindre de nos pratiques de protection des données
        personnelles,
      </p>
      <p className="mentions-para">
        En application du RGPD, les conditions d’exercice de ces droits peuvent
        varier selon la base de licéité du traitement mentionné dans le premier
        paragraphe.
      </p>
      <p className="mentions-para">
        Nous donnerons suite à tout exercice de droit dans les meilleurs délais
        et en tout état de cause dans un délai de 30 jours à compter de la
        réception de la demande.
      </p>
      <p className="mentions-para">
        <ul className="mentions-main-liste">
          <strong>Nous nous réservons le droit :</strong>
          <li className="mentions-liste">
            De demander une preuve de l’identité du demandeur en cas de doute
            raisonnable sur cette dernière et ce afin de respecter son
            obligation de confidentialité,
          </li>
          <li className="mentions-liste">
            De prolonger le délai de réponse de deux mois, informant alors le
            demandeur de cette prolongation et des motifs du report dans un
            délai d'un mois à compter de la réception de la demande,
          </li>
          <li className="mentions-liste">
            De refuser de répondre à un exercice de droit si celui-ci était
            considéré comme abusif (au vu de leur nombre, de leur caractère
            répétitif ou systématique).
          </li>
        </ul>
      </p>

      <h2 className="mentions-titre">
        Qui contacter pour toutes les demandes liées au RGPD ?
      </h2>
      <p className="mentions-para">
        Pour exercer vos droits, vous pouvez nous contacter :{" "}
      </p>

      <p className="mentions-para">LVPA WEB - Laignel Vergain Pierre-Alain</p>
      <p className="mentions-para">Adresse : 2 Rue Gustave Charpentier</p>
      <p className="mentions-para">14100-LISIEUX </p>
      {/* <p className="mentions-para">Téléphone : </p> */}
      <p className="mentions-para">
        Mail :{" "}
        <a href="mailto:info@lvpaweb.fr" target="_blank">
          info@lvpaweb.fr
        </a>{" "}
      </p>
      <p className="mentions-para">
        Directeur de la publication : Laignel Vergain Pierre-Alain
      </p>
      <p className="mentions-para"> </p>
      <p className="mentions-para mt50">
        Si, en dépit de nos efforts et de nos engagements, vous estimiez que vos
        droits concernant vos données personnelles n’étaient pas respectés, vous
        pouvez adresser une réclamation auprès de la Commission Nationale
        Informatique et Libertés : CNIL 3 Place de Fontenoy TSA 80715 75334
        Paris
      </p>
      <p className="mentions-para">Cedex 07</p>
      <h2 className="mentions-titre">
        Réserve de modification de la Politique de protection des données
      </h2>
      <p className="mentions-para">
        La présente Politique de protection des données personnelles peut être
        amenée à évoluer. Elles ont été élaborées à partir d'un modèle libre qui
        peut être téléchargé sur le site https://donnees.net. Comme nous
        développons constamment nos services, nous nous réservons le droit de
        modifier cette Politique de confidentialité, conformément aux
        dispositions légales en vigueur. Toute modification est publiée sur ce
        document en temps opportun. Nous vous conseillons de consulter
        régulièrement cette page pour prendre connaissance des éventuelles
        modifications ou mises à jour apportées à notre politique de
        confidentialité.
      </p>

      <h2 className="mentions-sous-titre">Cookies</h2>
      <p className="mentions-para">
        Nb : <strong>Notre site n'utilise pas de cookies. </strong>
      </p>
      <p className="mentions-para mb100">
        Dans le cadre de l’amélioration et de la rapidité de navigation des
        cookies peuvent être déposés sur votre ordinateur. Pour refuser ces
        cookies, veillez à paramétrer votre navigateur. Si vous souhaitez en
        savoir plus sur l’utilisation des cookies, nous vous invitons à vous
        rendre sur la page de la CNIL qui possède un article dédié.
      </p>
    </div>
  );
}
