// Import Ext dépendances
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
// Import Images
import logo from "../../Images/lvpasansfonsoff.png";
import burgerMenu from "../../Images/hamburger.svg";
// CSS
import "./Navbar.css";
import { useEffect } from "react";

export default function Navbar() {
  const [toggle, setToggle] = useState(false);
  const [toggleC, setToggleC] = useState(false);
  const [largeur, setLargeur] = useState(window.innerWidth);

  // Dropdown
  const [open, setOpen] = useState(false);

  const toggleNav = () => {
    setToggle(!toggle);

    console.log(toggle);
  };
  const toggleNavC = () => {
    setToggleC(true);

    console.log(toggleC, "toggle c");
  };

  const handleOpen = () => {
    setOpen(!open);

    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  //* Fonction qui permet de demander a l'utilisateur si souhaite vraiment quitter le site pour suivre un lien donné
  // function handleLinkClick(event) {
  //   event.preventDefault();
  //   const confirmed = window.confirm(
  //     "Ce lien redirige vers notre Blog, Voulez vous visitez notre Blog ?"
  //   );
  //   if (confirmed) {
  //     window.open(event.target.href, "_blank");
  //   }
  // }

  return (
    <nav>
      {(toggle || largeur > 600) && (
        <ul className="liste-item text-light m-0 p-0">
          <li className="item">
            <Link className="nav-link" to="/" onClick={toggleNav}>
              <img className="logo-site-lvpa-web" src={logo} alt="" />
            </Link>
          </li>
          <li className="item">
            <NavLink
              className={`nav-link ${
                window.location.pathname === "/" ? "activ" : ""
              }`}
              onClick={toggleNav}
              to="/"
            >
              Accueil
            </NavLink>
          </li>

          <li className="item">
            <NavLink
              className={({ isActive }) => {
                return isActive ? "nav-link activ" : "nav-link";
              }}
              onClick={toggleNav}
              to="/services"
            >
              Nos Services
            </NavLink>
          </li>

          <li className="item">
            <NavLink
              className={({ isActive }) => {
                return isActive ? "nav-link activ" : "nav-link";
              }}
              to="/creations"
              onClick={toggleNav}
            >
              Nos Créations
            </NavLink>
          </li>
          <li className="item">
            <NavLink
              className={({ isActive }) => {
                return isActive ? "nav-link activ" : "nav-link";
              }}
              to="/contact"
              onClick={toggleNav}
            >
              Contact
            </NavLink>
          </li>

          <li className="item">
            <NavLink to="https://blog.lvpaweb.fr"></NavLink>
            <a
              className="nav-link"
              href="https://blog.lvpaweb.fr/"
              target={"_blank"}
              // onClick={handleLinkClick}
            >
              Blog
            </a>
          </li>

          <li className="site">
            <NavLink to="/demande-de-site"></NavLink>
            site
          </li>
        </ul>
      )}

      <img className="logo-mini" src={logo} alt="" />
      <div className="btn-toggle imat" role="button" onClick={toggleNav}>
        {/* <img src={burgerMenu} alt="Icone de menu" /> */}
      </div>
    </nav>
  );
}
