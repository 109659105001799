import React from "react";
import "./Site.css";

import cahierDesCharges from "../..//Documents/Cahier des charges lvpaweb.pdf";
import Contact from "../Contact/Contact";

export default function Site() {
  return (
    <div className="container-site">
      <h3 className="site-title">
        Télécharger le cahier des charges à remplir puis renvoyer le nous <a href="mailto:p.laignelvergain@gmail.com">par mail</a> pour obtenir votre devis ou alors remplisser le formulaire de contact pour être recontacter
      </h3>

      <button className="telechargement">
        <a
          className="link-pdf"
          href={cahierDesCharges}
          download
          target="_blank"
        >
          {" "}
          Télécharger le cahier des charges en format PDF
        </a>
      </button>

      <Contact />
    </div>
  );
}
