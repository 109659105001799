// Import Ext
import { Routes, Route } from "react-router-dom";

// CSS
import "./App.css";
// Composants
import Navbar from "./Components/Navbar/Navbar";
import PageAccueil from "./Components/PageAccueil/PageAccueil";
import MesServices from "./Components/MesServices/MesServices";
import Creations from "./Components/Creations/Creations";
import Equipe from "./Components/Equipe/Equipe.";
import Contact from "./Components/Contact/Contact";
import { useEffect, useState } from "react";
import Posts from "./Components/Posts/Posts";
import Site from "./Components/Demande de site/Site";
import MentionsLegales from "./Components/MentionsLegales/MentionsLegales";
import NotFound from "./Components/Not found/NotFound";

function App() {

  useEffect(()=> {
    console.log("composant charger");
  },[App])


  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<PageAccueil />}></Route>
        <Route path="/services" element={<MesServices />}></Route>
        <Route path="/creations" element={<Creations />}></Route>
        {/* <Route path="/equipe" element={<Equipe />}></Route> */}
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/demande-de-site" element={<Site />}></Route>
        <Route path="/mentions-legales" element={<MentionsLegales />}></Route>
        <Route path="/*" element={<NotFound />}></Route>


        {/* TEST */}
        {/* <Route path="/post" element={<Posts />}></Route> */}
      </Routes>
    </div>
  );
}

export default App;
