import "./Creations.css";
import Navbar from "../Navbar/Navbar";
import { useEffect } from "react";
import Terre2mieuxEtre from "../../Images/tel-ordi-tab.webp";
import LmAuto from "../../Images/lmautomock.webp";
import { useNavigate } from "react-router-dom";

import cahierDesCharges from "../..//Documents/Cahier des charges lvpaweb.pdf";
import Contact from "../Contact/Contact";


export default function Creations() {

  const navigate = useNavigate()

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="main-creation">
      {/* <Navbar/> */}

      <div className="content-creations">
        <h2 className="titre-creations">Nos Créations</h2>
        <div className="techno">
          <p className="creations-description">
            Pour nos créations les technologies utilisées sont :{" "}
          </p>
          <ul className="techno-liste">
            <li className="techno-item item1">Html/Css/Sass</li>
            <li className="techno-item item2">React JS</li>
            <li className="techno-item item3">Node JS</li>
            <li className="techno-item item4">Express JS</li>
            <li className="techno-item item5">Mongo DB (Base de données)</li>
          </ul>
        </div>

        <div className="container-cartes">
          <div className="image-creations">
            <a
              className="lien-carte"
              href="https://terre2mieuxetre.com"
              target={"_blank"}
            >
              <div className="image-creations-content">
                <img className="image-carte-creations" src={Terre2mieuxEtre} alt="images du site terre 2 mieux etre.com" />
              </div>
              <div className="content-carte-bas">
                <h3 className="carte-titre-creations">Terre 2 Mieux Être</h3>
                <p className="creations-para">
                  Au programme séance de massage ou naturopathie.
                </p>
                <p className="creations-para">
                  Création du site Web responsive* Terre de mieux être pour notre
                  cliente dans le domaine du mieux être sur Six Fours Les Plages (83140)Var
                </p>
                <p className="creations-para">
                  <dfn className="definition">
                    *Adaptable sur plusieurs format (Ordinateur, Tablette et
                    téléphone)
                  </dfn>
                </p>
              </div>
            </a>
          </div>
          <div className="image-creations">
            <a
              className="lien-carte"
              href="https://lmauto14.fr"
              target={"_blank"}
            >
              <div className="image-creations-content">
                <img className="image-carte-creations" src={LmAuto} alt="Image du site lmauto14.fr" />
              </div>
              <div className="content-carte-bas">
                <h3 className="carte-titre-creations">LM AUTO </h3>
                <p className="creations-para">
                Votre réparateur automobile toutes marques sur Valorbiquet Saint Cyr du ronceray (14290) Calvados
                </p>
                <p className="creations-para responsive">
                  Site responsive*
                </p>
                <p className="creations-para">
                  <dfn className="definition">
                    *Adaptable sur plusieurs format (Ordinateur, Tablette et
                    téléphone)
                  </dfn>
                </p>
              </div>
            </a>
          </div>
        </div>
      <h3 className="titre-creations mt10">Demander un devis</h3>
      <div className="container-site">
      <h3 className="site-title">
        Pour obtenir un devis télécharger le cahier des charges à remplir et à nous renvoyer <a href="mailto:info@lvpaweb.fr">par mail</a>
      </h3>

      <button className="telechargement">
        <a
          className="link-pdf"
          href={cahierDesCharges}
          download
          target="_blank"
        >
          {" "}
          Télécharger le cahier des charges en format PDF
        </a>
      </button>
      </div>
      


    </div>
    </div>
  );
}
