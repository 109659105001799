import { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import { useState } from "react";

export default function Contact() {
  const form = useRef();
  const [rep, setRep] = useState("");
  const [nom, setNom] = useState("");
  const [sujet, setSujet] = useState("LVPA WEB (Formulaire de contact)");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  console.log(process.env);
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE,
        process.env.REACT_APP_TEMPLATE,
        form.current,
        process.env.REACT_APP_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.text) {
            setRep("Message envoyé");
            setTimeout(() => {
              setRep("");
            }, 2000);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );

    // e.target.reset();
    form.current.reset();
    setNom("");
    setSujet("LVPA WEB (Formulaire de contact)");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="main-contact">
      <div className="content-contact">
        <h2>Contact</h2>
        <form className="formulaire-contact" ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            value={sujet}
            name="sujet"
            onChange={(e) => setSujet(e.target.value)}
            readOnly
          />
          <input
            type="text"
            name="nom"
            value={nom}
            placeholder="Votre nom / prénom"
            onChange={(e) => setNom(e.target.value)}
            required
            maxLength={40}
          />
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Votre Email *"
          />

          <textarea
            type="text"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            placeholder=" Votre message"
          />
          <p>{rep}</p>
          <button className="envoi" type="submit">
            Envoyer
          </button>
        </form>
        <p className="contact-tel">
          Ou appeler nous au : <a href="tel:+07 75 74 04 65">07 75 74 04 65</a>
        </p>
      </div>
      <div></div>
    </div>
  );
}
