// Composants
import Creations from "../Creations/Creations";
import MesServices from "../MesServices/MesServices";

// CSS
import "./PageAccueil.css";
import "../MesServices/MesServices.css";
import Equipe from "../Equipe/Equipe.";
import Contact from "../Contact/Contact";
import { useEffect } from "react";

import accueil from "./../../Images/creation_site.57df683d5dd98568b3ee.jpg";

import Footer from "../Footer/Footer";

export default function PageAccueil() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="accueil">
      <section className="fond-t">
        <img className="fond" src={accueil} alt="" />
      </section>
      {/* <Navbar/> */}
      <section className="section-services">
        <MesServices />
      </section>

      <section className="section-creations">
        <Creations />
      </section>

      {/* <section className="section-equipe">
        <Equipe />
      </section> */}

      <section className="section-contact">
        <Contact />
      </section>

      <section className="section-footer">
        <Footer />
      </section>
    </div>

    // how to add load more es7
  );
}
